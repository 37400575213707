import { useMemo, useState } from "react";
import "./Welcome.css";

export interface WelcomeProps {
  visible: boolean;
  onClose: () => void;
}

const steps = [
  {
    title: "CLUB M Brasil - Metaverso",
    content: (
      <>
        <p>Seja bem-vindo ao metaverso do CLUB M</p>
        <p>Antes de entrar, queremos te mostrar algumas dicas</p>
      </>
    ),
  },
  {
    title: "Movimentação",
    content: (
      <>
        <p>
          Para se movimentar no mundo virtual utilize as teclas WASD e o clique e arraste do mouse para rotacionar.
        </p>
        <img alt="wasd" src="./wasd.png" height={100} />
      </>
    ),
  },
  {
    title: "Avatar",
    content: (
      <>
        <p>
          Antes de entrar na sala, você pode alterar seu nome e alterar seu
          avatar clicando em "Avatar Settings"
        </p>
        <img alt="frame" src="./frame.png" height={150} />
      </>
    ),
  },
  {
    title: "Utilize um Computador",
    content: (
      <>
        <p>
          A experiência no metaverso em um smartphone pode ser frustante
          dependendo do dispositivo utilizado.
        </p>
        <p>De preferência a um computador ou notebook.</p>
      </>
    ),
  },
];

const Welcome = (props: WelcomeProps) => {
  const [currentStep, setCurrentStep] = useState(0);
  const isLastStep = useMemo(
    () => currentStep === steps.length - 1,
    [currentStep]
  );
  const isFirstStep = useMemo(() => currentStep === 0, [currentStep]);
  const step = useMemo(() => steps[currentStep], [currentStep]);

  if (!props.visible) return null;

  return (
    <div className="welcome-background">
      <div className="welcome-modal">
        <button className="welcome-close-button" onClick={props.onClose}>
          X
        </button>
        <h1 className="welcome-title">{step.title}</h1>
        <div className="welcome-content">{step.content}</div>
        <div className="welcome-btn-container">
          {!isFirstStep && (
            <button
              className="btn btn-secondary"
              onClick={() => setCurrentStep((s) => s - 1)}
            >
              Anterior
            </button>
          )}
          {!isLastStep && (
            <button
              className="btn btn-primary"
              onClick={() => setCurrentStep((s) => s + 1)}
            >
              Próxima
            </button>
          )}
          {isLastStep && (
            <button className="btn btn-primary" onClick={props.onClose}>
              Entendi
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Welcome;
