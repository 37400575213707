import { useState } from "react";
import Welcome from "./Welcome";
const FRAME_LINK = "https://framevr.io/clubm-brasil#entrada";
const FRAME_LINK_AUDITORIO = "https://framevr.io/club-auditorio";

function App() {
  const [welcomeVisible, setWelcomeVisible] = useState(true);
  const searchParams = new URLSearchParams(document.location.search);
  return (
    <>
      <iframe
        className="iframe"
        title="project"
        frameBorder={0}
        allow="camera;microphone; display-capture; vr; xr; accelerometer; magnetometer; gyroscope; webvr;webxr;"
        allowFullScreen
        src={searchParams.get("room") === "auditorio" ? FRAME_LINK_AUDITORIO : FRAME_LINK}
      />
      <Welcome
        visible={welcomeVisible}
        onClose={() => setWelcomeVisible(false)}
      />
    </>
  );
}

export default App;
